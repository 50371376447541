const GOOGLE_PLACE_KEY = 'AIzaSyAbcVfeiTr0sdz1M8eCYzNeUKqyU4XDMIc'
// dev
const baseUrl = 'https://apidev.midnightexpress.net/'
const fileUrl = 'https://d3t0ne1nnzkb15.cloudfront.net/'
const port = 4001
// stage
// const baseUrl = 'http://54.177.172.182:3002/'
// const fileUrl = 'https://d3t0ne1nnzkb15.cloudfront.net/'
// const port = 4002
// prod
// const baseUrl = 'https://api.midnightexpress.net/'
// const fileUrl = ' https://d2brvl0gslsyz2.cloudfront.net/'
// const port = 4000
module.exports = { baseUrl, fileUrl, port,GOOGLE_PLACE_KEY }
