import { Outlet } from "react-router"
import MainHeader from "../components/MainHeader"
import Footer from "../components/Footer"

const OutRoutes = () => {
    return (
        <>
            <MainHeader />
            <Outlet />
            <Footer />
        </>
    )
}

export default OutRoutes;