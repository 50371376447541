import { Button } from "antd";
import { Link } from "react-router-dom";

const NoDataFound = () => {
    return (
        <>
            <div className="not_found_div">
            {/* <a href="https://anywhererentalcar.com/#/quick-reservation" target="_blank" className="title_font fs-5">Online Reservations</a> */}
                <div className="text-center shop_text">
                    <h1 className='oops_text'>Oops !</h1>
                    <h5 className='title_font'>404 - PAGE NOT FOUND</h5>
                    <h6 className='small_font'>The page you are looking for might have been removed <br /> had its name changed or temporarily unavailable</h6>
                    <Link to='/'><Button type="ghost" className='submit_btn w-auto px-5 mt-4' style={{ borderRadius: "50px" }}>GO TO HOMEPAGE</Button></Link>
                </div>
            </div>
        </>
    )
}

export default NoDataFound;
