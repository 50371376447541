import { useState } from "react";
import { FaFacebookF, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { Button, Drawer, Typography } from "antd"
import { FiLogIn } from "react-icons/fi";
import { IoIosMail } from "react-icons/io";
import { Link, NavLink, useLocation } from "react-router-dom"
import { BiSolidPhoneCall } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../assets/logo.png"
import appStore from '../assets/playApple.png';
import playStore from '../assets/playGoogle.png';


const Header = () => {
    const location = useLocation();
    const { pathname } = location;
    const [open, setOpen] = useState(false);

    return (
        <div className="header_background">
            <div className="common_container">
                <div className="row d-flex align-iteClick to ms-center">
                    <div className="col-4">
                        <div className="d-flex align-items-center gap-3 hide">
                            <Button type="ghost" className="header_btn" style={{ background: '#5F92D4' }} icon={<FiLogIn size={18} />}>Sign In</Button>
                            <Button type="ghost" className="header_btn" style={{ border: '2px solid #5F92D4' }} icon={<IoIosMail className="mb-1" size={22} />}>Email</Button>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="logo text-center">
                            <Link to="/"> <img src={logo} alt="logo_" /></Link>
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-between hide">
                        <div className="d-flex align-items-center justify-content-end gap-3">
                            <FaFacebookF size={22} color="#5f92d4" />
                            <FaTwitter size={25} color="#5f92d4" />
                            <FaYoutube size={30} color="#5f92d4" />
                            <FaLinkedin size={26} color="#5f92d4" />
                        </div>
                        <div className="text-end d-flex">
                            <a href='https://testflight.apple.com/join/y2fVBLeH' target="_blank">
                                <img src={appStore} alt="" width='90%' style={{ cursor: 'pointer', }} />
                            </a>
                            <a href='https://webapp.diawi.com/install/NXq3G8' target="_blank">
                                <img src={playStore} alt="" width='90%' style={{ cursor: 'pointer' }} />
                            </a>
                        </div>
                    </div>
                    <div className="d-lg-none d-xl-none">
                        <GiHamburgerMenu color="#fff" size={30} onClick={() => setOpen(!open)} />
                    </div>
                </div>

                {/* menus */}
                <div className="navbar_main_div mt-4 hide">
                    <div className="d-flex align-items-center navbar ps-5 py-2" style={{ backgroundColor: '#5F92D4' }}>
                        <NavLink to={'/'} className='nav_link' style={{ fontWeight: pathname == '/' ? '700' : "400" }}>RIDERS HOME</NavLink>
                        <NavLink to='/operation' className='nav_link' style={{ fontWeight: pathname == '/operation' ? '700' : "400" }}>JOIN SUPPLIER NETWORK</NavLink>
                        <NavLink to={'/contact_us'} style={{ fontWeight: pathname == '/contact_us' ? '700' : "400" }} className='nav_link'>CONTACT US</NavLink>
                        <div className="call_icon_circle">
                            <BiSolidPhoneCall className="mt-2" color="#5F92D4" size={30} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2 number_div py-2" style={{ background: '#000' }}>
                        <div className="">
                            <Typography className="phne_number">888-999-8640</Typography>
                            <small className="text-white m-0">Since 1995</small>
                        </div>
                    </div>
                </div>
                {/* content */}
                {/* <div className="text_div">
                <div className="text-center mt-2">
                    <h1 className="text-white fw-bold title_txt" >Take Control</h1>
                    <h1 className="text-white fw-bold title_txt" >Transform Your Trip</h1>
                    <h5 className="text-white header_txt mt-4">Embrace the Future: Navigate with Cutting-Edge Technology</h5>
                    <Button type="ghost" htmlType="submit" className="slider_imgButton" style={{ height: 60, fontSize: '1rem', color: "#000" }}>Click to Explore Ways Cutting-Edge Technology</Button>
                </div>
            </div> */}

                <Drawer placement="right" onClose={() => setOpen(!open)} open={open}>
                    <div className="d-flex align-items-center gap-2 number_div py-2 mb-5" style={{ background: '#000' }}>
                        <div className="">
                            <dt className="phne_number">888-999-8640</dt>
                            <small className="text-white m-0">Since 1995</small>
                        </div>
                    </div>
                    <div className="mobile_links">
                        <NavLink onClick={() => setOpen(!open)} to="/" className={pathname == '/' ? 'active_page' : ''}>RIDERS HOME</NavLink>
                        <NavLink onClick={() => setOpen(!open)} to="/operation" className={pathname == '/operation' ? 'active_page' : ''}>JOIN SUPPLIER NETWORK</NavLink>
                        <NavLink onClick={() => setOpen(!open)} to="/contact_us" className={pathname == '/contact_us' ? 'active_page' : ''}>CONTACT US</NavLink>
                    </div>

                    <div className="text-end d-flex mt-5">
                        <a href='https://testflight.apple.com/join/y2fVBLeH' target="_blank">
                            <img src={appStore} alt="" width='100%' style={{ cursor: 'pointer' }} />
                        </a>
                        <a href='https://i.diawi.com/xZCEzF' target="_blank">
                            <img src={playStore} alt="" width='100%' style={{ cursor: 'pointer', margin: '0 0 0 5px' }} />
                        </a>
                    </div>
                </Drawer>
            </div>
        </div>

    )
}

export default Header