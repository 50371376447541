import { useEffect, useState } from "react";
import { Button, Col, Input, Row, Typography } from "antd"
import { FaFacebookF, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { mainAction } from "../redux/mainAction";
import solidAppMaker from "../assets/solidappmakerLogo.png"
const Footer = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const date = new Date()
    const dispatch = useDispatch();
    const [useFulLink, setUseFulLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('')

    const getUseFulLink = async () => {
        const successCb = (response) => {
            setUseFulLink(response?.data?.useful_links)
        }
        const failureCb = (response) => {
            toast.error(response?.message)
        }
        await dispatch(mainAction(`adminAuth/getGeneralData?type=useful_links`, 'get', '', successCb, failureCb, false))
    }

    const handleSubscribeNewsLetter = () => {
        if (!emailRegex.test(email)) {
            setLoading(false);
            toast.error("Please enter a valid email address");
            return;
        }
        setLoading(true)
        let data = {
            email: email
        }
        const successCb = (res) => {
            setLoading(false)
            toast.success(res?.message)
            setEmail('')
        }
        const failureCb = (res) => {
            setLoading(false)
            toast.error(res?.message)
        }
        dispatch(mainAction(`userAuth/add_newsletter`, 'post', data, successCb, failureCb))
    }


    useEffect(() => {
        getUseFulLink();
    }, [])

    return (
        <div style={{ backgroundColor: "#202020" }} className="">
            <div className="common_container p-4">
                <Row gutter={[30, 30]}>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <div className="">
                            <Typography className="text-white fw-bold" style={{ fontSize: '1.3rem' }}>About</Typography>
                            <Typography className="text-white mt-2" style={{ fontSize: '1rem', width: '80%', textAlign: 'start' }}>We are building the world's most robust network of car service. If you looking to play a part in making a positive impact in your community by advancing the safety in transportation? join us.</Typography>
                            <div className="d-flex align-items-center gap-4 mt-3">
                                <FaFacebookF size={22} color="#5F92D4" />
                                <FaTwitter size={25} color="#5F92D4" />
                                <FaYoutube size={30} color="#5F92D4" />
                                <FaLinkedin size={30} color="#5F92D4" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div className="">
                            <Typography className="text-white fw-bold" style={{ fontSize: '1.3rem' }}>Useful Links</Typography>
                            <Typography className="text-white footer_link mt-2">About</Typography>
                            <Typography className="text-white footer_link">Get a Ride</Typography>
                            <Typography className="text-white footer_link">Our Reviews</Typography>
                            <Typography className="text-white footer_link">Latest News</Typography>
                            <Typography className="text-white footer_link">Contact</Typography>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div className="" style={{ textAlign: 'start' }}>
                            <Typography className="text-white fw-bold" style={{ fontSize: '1.3rem' }}>Useful Links</Typography>
                            <Typography className="text-white footer_link mt-2">{useFulLink?.address}</Typography>
                            <div className="d-flex align-items-center gap-2 mt-2">
                                <MdEmail color="#5F92D4" size={28} />
                                <Typography className="text-white footer_link">{useFulLink?.email}</Typography>
                            </div>
                            <div className="d-flex align-items-center gap-2 mt-2">
                                <MdLocalPhone color="#5F92D4" size={28} />
                                <Typography className="text-white footer_link">{useFulLink?.ph_number}</Typography>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <Typography className="text-white fw-bold" style={{ fontSize: '1.3rem' }}>Newsletter</Typography>
                        <Typography className="text-white footer_link mt-2">Sign up now for our mailing list to get all latest news and updates from Midnight Express company.</Typography>
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} className="input_box mt-3" variant="borderless" placeholder="Enter Your email" />
                        <Button loading={loading} type="ghost" className="header_btn mt-4" onClick={handleSubscribeNewsLetter} style={{ background: '#5F92D4' }}>Subscribe</Button>
                    </Col>
                </Row>
                <div className="mt-4">
                    <hr style={{ border: '1px solid #5F92D4' }} />
                    <Typography className="text-center text-white" style={{ fontSize: '1rem' }}>&copy; {date.getFullYear()} - All Rights Reserved - <small className="" style={{ color: '#5F92D4', fontSize: '1rem' }}>Midnight Express TNC</small> | <NavLink to='/privacy&policy' className="text-white" style={{ fontSize: '1rem', textDecoration: 'underline' }}>Privacy Policy</NavLink> and <NavLink to='/terms&conditions' className="text-white" style={{ fontSize: '1rem', textDecoration: 'underline' }}>Terms of Use</NavLink> | Designed & Developed by <a href="https://solidappmaker.com/" target='_blank'><img src={solidAppMaker} alt="logo" className='solidApp_logo' /></a></Typography>
                </div>
            </div>
        </div>
    )
}
export default Footer