import { Carousel } from "antd"
import Header from "./Header"

const MainHeader = () => {
    return (
        <>
            {/* <div className="main_header" style={{ position: 'relative' }}>
                <Carousel>
                    <div className="slider_backImage"></div>
                    <div className="slider_backImage"></div>
                    <div className="slider_backImage"></div>
                </Carousel>
                <div className="all_content">
                </div>
            </div> */}
            <Header />
            {/* <div style={{height:'230px'}}></div> */}
        </>
    )
}
export default MainHeader