import car from '../assets/arclogo.png';
const Loader = () => {
    return (
        <>
            <div className="loader_div">
                <img src={car} alt='' width='10%' />
            </div>
        </>
    )
}

export default Loader
